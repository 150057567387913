import { sum } from 'lodash-es';

import { ProfileError } from '../../../errors';
import { finishNode, parseTimestamp, updateNode } from '../common';
import { getCategoryByType } from '../labels';
import {
  NodeTimings,
  ProfileBuilder,
  ProfileNode,
  ProfileNodeWithTimings,
  TransactionEvent,
} from '../types';
import { NodeEvent } from './types';

export class ProfileBuilderV1 implements ProfileBuilder {
  root: ProfileNode;
  nodesMap: Map<string, ProfileNode>;

  constructor() {
    this.root = {
      core: {
        id: '0',
        desc: { type: 'Transaction' },
        category: 'Other',
        latestProgress: null,
        startedAt: new Date(0),
        finishedAt: null,
        path: [],
      },
      children: [],
    };
    this.nodesMap = new Map<string, ProfileNode>();
  }

  addEvents(events: TransactionEvent[]): void {
    events.forEach(rawEvent => {
      const { type, timestamp, event } = rawEvent;
      const eventDate = parseTimestamp(timestamp);

      switch (type) {
        case 'profiler.StartNode': {
          const { node_id, desc, parent_id } = event;

          if (this.nodesMap.has(node_id)) {
            break;
          }

          // Transaction root node has no parent_id
          if (!parent_id) {
            this.root.core.id = node_id;
            this.root.core.path = [node_id];
            this.root.core.startedAt = eventDate;
            this.nodesMap.set(node_id, this.root);
            break;
          } else {
            const parentNode = parent_id && this.nodesMap.get(parent_id);

            if (!parentNode) {
              throw new ProfileError(
                `Parent node ${parent_id} is missing for node ${node_id}`,
                rawEvent,
              );
            }

            const node: ProfileNode = {
              core: {
                id: node_id,
                desc,
                category: getCategoryByType(desc.type),
                latestProgress: null,
                startedAt: eventDate,
                finishedAt: null,
                path: [...parentNode.core.path, node_id],
              },
              children: [],
            };

            this.nodesMap.set(node_id, node);
            parentNode.children.push(node);
            break;
          }
        }

        case 'profiler.UpdateNodeProgress': {
          updateNode(this.nodesMap, rawEvent);
          break;
        }

        case 'profiler.FinishNode': {
          finishNode(this.nodesMap, eventDate, event.node_id.toString());
          break;
        }

        default: {
          throw new ProfileError(
            `Unhandled event type of "${type}" in events to profile conversion`,
            rawEvent,
          );
        }
      }
    });
  }

  extract(lastTime: Date): ProfileNodeWithTimings {
    return computeTimings(this.root, lastTime);
  }
}

// only exported for testing
export function computeTimings(
  node: ProfileNode,
  lastTime: Date,
): ProfileNodeWithTimings {
  // Base case: if the node has no children, return the core and timings.
  if (node.children.length === 0) {
    return {
      core: node.core,
      timings: getTimingsForNode(node, [], lastTime),
      children: [],
    };
  }

  // Recursive case: if the node has children, compute their values.
  const children = node.children.map(child => {
    return computeTimings(child, lastTime);
  });

  return {
    core: node.core,
    timings: getTimingsForNode(node, children, lastTime),
    children,
  };
}

function getTimingsForNode(
  node: ProfileNode,
  children: ProfileNodeWithTimings[],
  lastTime: Date,
): NodeTimings {
  const endTime = node.core.finishedAt || lastTime;

  let selfTime = 0;
  let childrenRunning = 0;
  let lastTimeNoChildrenRunning = node.core.startedAt.getTime();

  // Create an array of start and stop events for all child nodes
  const events: NodeEvent[] = children.flatMap(child => {
    return [
      { type: 'start', timestamp: child.core.startedAt.getTime() },
      {
        type: 'stop',
        timestamp: child.core.finishedAt?.getTime() || lastTime.getTime(),
      },
    ];
  });

  events.sort((a, b) => a.timestamp - b.timestamp);

  // Calculate the self time of the node
  events.forEach(event => {
    switch (event.type) {
      case 'start': {
        if (childrenRunning === 0) {
          selfTime += event.timestamp - lastTimeNoChildrenRunning;
        }

        childrenRunning++;
        break;
      }

      case 'stop': {
        childrenRunning--;

        if (childrenRunning === 0) {
          lastTimeNoChildrenRunning = event.timestamp;
        }

        break;
      }
    }
  });

  // Finalize the selfTime calculation
  // selfTime = wallTime - childWallTime so if wallTime is 0, selfTime should be also 0
  selfTime += Math.max(endTime.getTime() - lastTimeNoChildrenRunning, 0);

  // there could be race conditions where endTime was briefly earlier than the node’s start time,
  // it occurs because lasTime is taken on the client and it is used to mark endTime when finishedAt is not available yet from server side,
  // and node.startedAt is taken on the server side. So, in this case we need to make sure wallTime is not negative
  const wallTime = Math.max(
    endTime.getTime() - node.core.startedAt.getTime(),
    0,
  );

  const childValues = sum(children.map(c => c.timings?.totalTime || 0));

  return {
    done: !!node.core.finishedAt,
    wallTime,
    totalTime: selfTime + childValues,
    selfTime,
  };
}
