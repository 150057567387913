import { ProfileNodeDesc } from './inputTypes';
import { ProfileNodeWithTimings } from './types';

// For nodes that are too 'internal' to expose to users,
// 'subsume' them into their parents. This means:
// - add their time to their parent
// - remove them from the tree
// - add their children as children of their parent.
export function subsumeInternalNodes(
  node: ProfileNodeWithTimings,
): ProfileNodeWithTimings {
  const updatedTimings = node.timings;
  const updatedChildren: ProfileNodeWithTimings[] = [];

  for (const child of node.children) {
    if (shouldBeSubsumed(child.core.desc)) {
      updatedTimings.selfTime += child.timings.selfTime;
      // Only need to update `selfTime, since descendants' totalTime has already been
      // added to `child`'s totalTime.

      for (const grandChild of child.children) {
        updatedChildren.push(subsumeInternalNodes(grandChild));
      }
    } else {
      updatedChildren.push(subsumeInternalNodes(child));
    }
  }

  return {
    core: node.core,
    timings: updatedTimings,
    children: updatedChildren,
  };
}

function shouldBeSubsumed(node: ProfileNodeDesc) {
  return node.type === 'EvaluateSubdomain';
}
